@import "./variables.scss";

.card {
  &__info {
    background: rgba($primary-color, 0.3) !important;
    margin-bottom: 1rem;
    .card-title {
      color: $primary-color;
    }
    .card-subtitle {
      color: $secondary-color;
      font-size: 20px;
      font-weight: 700;
    }
  }
  &__export {
    padding: 20px;
    background-color: #5cd4c9 !important;
    height: 150px !important;
    width: 150px;
    text-align: center;
    color: rgba($secondary-color, 0.4);
    .card-title{
      color: white;
      font-weight: 400;
      margin-top: 8px;
      font-size: 16px;
    }
  }
  &__chart {
    display: block;
    position: relative;
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    border: 5px solid $primary-color;
    padding: 1.3rem;
    z-index: 1;
    min-height: 350px;
    top: 0;
    left: 0;
    transition: all 0.7s ease;
    overflow: hidden;
  }
  &__title {
    font-size: 1.3rem;
  }
  &__opened {
    // display: none;
    position: fixed;
    left: 300%;
    top: 50%;
    transform: translate(-400%, -50%);
    z-index: 1099;
    max-height: 90vh;
    overflow-y: scroll;
    width: 70vw;
    background-color: white;
    border-color: rgba(0, 0, 255, 0.5);
    // animation: card-animation 1s ease;
    &-close-icon {
      position: fixed;
      top: 10px;
      right: 10px;
      font-size: 2rem;
    }
    .btn {
      background-color: rgba(0, 0, 255, 0.5) !important;
    }
  }
  &__skeleton {
    height: 100%;
    width: 100%;
    position: absolute;
    left: -150%;
    top: 0;
    height: 100%;
    width: 250%;
    background: repeating-linear-gradient(
      to right,
      transparent 0%,
      $skeleton-color 10%,
      transparent 20%,
      $skeleton-color 30%,
      transparent 40%,
      $skeleton-color 50%,
      transparent 60%,
      $skeleton-color 70%,
      transparent 80%,
      $skeleton-color 90%,
      transparent 100%
    );
    animation: load_skeleton 2.5s linear infinite;
  }
}

@keyframes load_skeleton {
  from {
    left: -150%;
  }
  to {
    left: 0%;
  }
}
