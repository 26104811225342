@import "./variables.scss";

h2,
h3 {
  color: $secondary-color;
  font-weight: 500;
  opacity: 0.8;
  margin-top: 40px;
}

.height-inherit {
  height: inherit;
}

.container-row {
  flex: 1;
  flex-direction: row;
}

footer {
  background-color: $secondary-color;
  color: white;
}

a.link-primary {
  color: $primary-color !important;
  &:hover {
    color: $primary-color-hover !important;
  }
}

.bgd-primary {
  background-color: $primary-color;
}

.bgd-secondary {
  background-color: $secondary-color !important;
  color: $primary-color;
}

.card {
  border-radius: 15px !important;
  border: 0 !important;
}

.input-text {
  display: block !important;
  margin: 0;
  color: inherit !important;
  width: 100% !important;
  font-family: inherit !important;
  font-weight: inherit !important;
  border: 1px solid #b0bec5 !important;
  border-radius: 0.4rem !important;
  transition: box-shadow 0.5s !important;
}

.input-text::placeholder {
  color: #b0bec5 !important;
}

.input-text:focus {
  outline: none !important;
  box-shadow: 0.2rem 0.8rem 1.6rem $primary-color !important;
}

.online-cprimary {
  color: $primary-color !important;
  border-color: $primary-color !important;
  &:hover {
    background-color: $primary-color !important;
    color: $secondary-color !important;
  }
}

@media (max-width: 577px) {
  .online-cprimary {
    margin-top: 15px;
  }

  .button-hamburger {
    margin-right: 15px;
  }
}

.online-csecondary {
  color: $secondary-color !important;
  border-color: $secondary-color !important;
  &:hover {
    background-color: $secondary-color !important;
    color: $primary-color !important;
    font-weight: bold !important;
  }
}

.msg-error {
  color: red;
  background-color: rgba($color: red, $alpha: 0.35);
  padding: 5px;
  border-radius: 5px;
  font-weight: 600;
  margin-bottom: 20px;
  text-align: center;
}

.card-info {
  border-bottom: 1px solid #b0bec5;
  margin-top: 10px;
  .title {
    opacity: 0.7;
  }
  .info {
    color: "black";
    font-weight: bold;
  }
}

.color-headline {
  color: $primary-color;
  font-weight: 600;
  font-size: 22px;
}

.badge-info {
  margin-left: 7px;
  margin-right: 7px;
  background-color: $secondary-color;
  color: $primary-color;
  font-weight: 600;
  font-size: 22px;
  border-radius: 20px;
  padding: 10px;
  text-align: center;
  margin-bottom: 10px;
}

.card-container {
  width: 100%;
  padding-bottom: 20px;
}

.card-image {
  text-align: center;
  margin-top: 30px;
  margin-bottom: 35px;
}

.pd-top-bottom {
  padding-top: 45px;
  padding-bottom: 45px;
}

.full-height-vh {
  height: 100vh;
}

.table > :not(:first-child) {
  border-top: none !important;
}

.page-item.active .page-link {
  background-color: transparent !important;
  color: #0d6efd !important;
}

.box-message-plug {
  background: #f2f2f2;
  color: rgb(41, 41, 41);
  min-height: 10rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  width: 100% !important;
  margin: 1rem 0.5rem;
  span {
    font-size: 20px;
  }
}

.table-selected-lists {
  height: auto;
  overflow-y: auto;
  max-height: 30rem;
}

.modal {
  .modal-footer {
    border-top: none;
  }

  span {
    opacity: 0.6;
    font-size: 12px;
  }
  .error {
    color: $primary-color;
  }
}

svg {
  cursor: pointer;
}

.loading {
  color: $primary-color;
}

.color-text {
  color: rgba(255, 255, 255, 0.55);

  &:hover {
    color: white;
  }
}

.width-45-px {
  width: 45px;
}
.ml-10-px {
  margin-left: 10px;
}

.language-select {
  .form-control {
    color: white;
    width: auto;
    border: none;
    padding: 0.3rem;
    cursor: pointer;
    background-color: #ffffff4e;
    &:hover {
      background-color: #ffffff88;
    }
  }
}

.text-right {
  text-align: right;
}
