.wrapper_dnd_products {
  background: #ffffff !important;
  padding: 4px;
  overflow-x: scroll;
  min-width: unset !important;
  max-width: 100%;
  max-height: 500px;
  & > div {
    display: flex;
    justify-content: space-between;
    border-radius: 0.375rem;
    &:hover {
      background-color: darken(rgb(110, 194, 253), 20%) !important;
    }
  }
}
