@import "./variables.scss";
.admin__layout {
  .sidebar {
    background-color: $primary-color;
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    width: inherit;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: scroll;
    * {
      margin: 10px 0;
      word-wrap: break-word;
    }
    a {
      color: rgba(255, 255, 255, 0.5);
      padding: 0 10px;
      border-radius: 10px;
      transition: all 0.5s ease-in-out;
    }
    &.sidebar-desktop {
      .active {
        color: white !important;
        text-decoration: underline;
        background-color: rgba(0, 0, 0, 0.11);
      }
    }
    &.sidebar-mobile {
      .active {
        color: white !important;
      }
    }
  }
  @media (max-width: 1100px) {
    .sidebar-desktop {
      font-size: 80%;
      .active {
        color: white !important;
        text-decoration: underline;
        background-color: transparent !important;
      }
    }
  }
  @media (max-width: 900px) {
    .sidebar-desktop {
      display: none;
    }
    .sidebar-mobile {
      display: flex;
      li {
        align-self: center;
      }
    }
  }
  @media (min-width: 901px) {
    .sidebar-desktop {
      display: flex;
    }
    .sidebar-mobile {
      display: none;
    }
  }
}
.main {
  margin-top: 28px;
  flex: 1;
}

.cursor-pointer {
  cursor: pointer;
}
