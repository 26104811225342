@import './variables.scss';
.button{
    &-rounded{
        border-radius: 30px !important;
        background-color: $primary-color !important;
        border: none !important;
    }
}
.button-go-back {
    border-radius: 30px !important;
    background-color: $primary-color !important;
    border: none !important;
    color: white !important;
    position: fixed;
    width: fit-content;
    display: flex;
    justify-content: flex-start;
    top: 0.5vh;
    left: 18vw;
}
  