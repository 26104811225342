.row_gallery {
  margin-left: 5px !important;
  margin-right: 5px !important;
  padding-bottom: 10px !important;
  padding-top: 5px !important;
}
.img_cover {
  height: 80px;
  width: 140px;
  object-fit: cover;
  padding-top: 5px;
  transition: transform 400ms ease-out;
}
.img_cover:hover {
  transform: scale(1.15);
}
.gallery-col {
  padding-top: 10px;
}

.gallery {
  display: flex;
  flex-wrap: wrap;
  margin: -1rem -1rem;
}

.gallery-item {
  flex: 1 0 24rem;
  margin: 1rem;
  box-shadow: 0rem 0rem 0.3rem rgba(0, 0, 0, 0.4);
  overflow: hidden;
  height: 100%;
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
  position: relative;
}

.gallery-image {
  cursor: pointer;
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 400ms ease-out;
}

.selected {
  opacity: 0.5;
}

.gallery-image:hover {
  transform: scale(1.15);
}
.cont_img_file {
  position: relative;
  text-align: center;
  width: 200px;
  height: 120px;
  background-color: #696161;
}
.img_file {
  margin-top: 10px;
  object-fit: cover;
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 400ms ease-out;
  opacity: 0.5;
}
.icon_delete {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 1 !important;
  color: #fff !important;
  font-size: 30px;
  cursor: pointer;
}

.icon_delete:hover {
  color: #e86e6e !important;
}

.thumbnail {
  border: 1px solid #ddd;
  border-radius: 4px;
  width: 50px;
}

.center {
  justify-items: center;
  padding: 1rem;
}

.up-down-margin {
  margin: 1rem 0;
}
