@import './variables.scss';

.bar, .line, .polar{
	&__chart{
		height: auto;
		width: 100%;
		position: relative;
		overflow: hidden;
	}
}
.pie, .polar{
	&__chart{
		max-width: 500px;
		max-height: 500px;
		margin: 0 auto;
		position: relative;
		overflow: hidden;
	}
}
.chart{
	&__title{
		font-size: 1.7rem;
	}
	// &__skeleton{
	// 	height: 100%;
	// 	width: 100%;
	// 	position: absolute;
	// 	left: -150%;
	// 	top: 0;
	// 	height: 100%;
	// 	width: 250%;
	// 	background: repeating-linear-gradient(to right,
	// 				transparent 0% ,
	// 				$skeleton-color 10%,
	// 				transparent 20% ,
	// 				$skeleton-color 30%,
	// 				transparent 40%,
	// 				$skeleton-color 50%,
	// 				transparent 60%,
	// 				$skeleton-color 70%,
	// 				transparent 80%,
	// 				$skeleton-color 90%,
	// 				transparent 100%,
	// 				)
	// 				// SECOND OPTION : 
	// 				// linear-gradient(to right, transparent 0%, $skeleton-color 50%, transparent 100%),linear-gradient(to top,transparent 0%, $skeleton-color 50%, transparent 0%),
					
	// 				;
	// 	animation: load_skeleton 2.5s linear infinite;
	// }
}

// @keyframes load_skeleton {
// 	from {
// 		left:-150%;
// 	}
// 	to {
// 		left: 0%;
// 	}
// }