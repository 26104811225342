.actions-row-buttons {
  display: flex;

  margin: 0;
  padding: 0 !important;
  .action-btn-edit,
  .action-btn-delete,
  .action-btn-add {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100% !important;
    width: 100% !important;
    padding: 1rem !important;
    cursor: pointer;
    transition: 0.3s;

    svg {
      color: white;
    }
  }
  .action-btn-edit {
    background-color: rgba(0, 191, 255, 0.7);
  }
  .action-btn-edit:hover {
    background-color: rgba(0, 191, 255, 0.9);
  }
  .action-btn-delete {
    background-color: rgba(255, 68, 0, 0.7);
  }
  .action-btn-delete:hover {
    background-color: rgba(255, 68, 0, 0.9);
  }
  .action-btn-add {
    background-color: rgba(35, 174, 0, 0.7);
  }
  .action-btn-add:hover {
    background-color: rgba(35, 174, 0, 0.9);
  }
}

.selected-list-table-row {
  display: flex;
  flex-wrap: nowrap;
}

.selected-list-row-name {
  width: 100%;
}

.row-buttons-actions {
  display: flex;
  justify-content: flex-end;
  .btn {
    max-width: 10rem;
    margin: 5rem 1rem 2rem 1rem !important;
  }
}
