@import './variables.scss';
@import './buttons.scss';
@import './cards.scss';
@import './dnd.scss';
@import './group_lists.scss';
@import './management.scss';
@import './tables.scss';
@import './group_sliders.scss';

.border-100 {
  border-radius: 100%;
}
a {
  text-decoration: none !important;
}

::-webkit-scrollbar {
  width: 1vw;
}
::-webkit-scrollbar-thumb {
  background-color: $primary-color;
  border-radius: 12px;
}
::-webkit-scrollbar-track {
  background-color: transparent;
}

label {
  margin: 5px 0;
}

.box-paddings-center {
  display: flex;
  width: 100%;
  padding: 10rem;
  justify-content: center;
  align-items: center;
}
